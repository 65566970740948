import { render, staticRenderFns } from "./tanghuay_v2.vue?vue&type=template&id=ad51caf0&scoped=true"
import script from "./tanghuay_v2.vue?vue&type=script&lang=js"
export * from "./tanghuay_v2.vue?vue&type=script&lang=js"
import style0 from "./tanghuay_v2.vue?vue&type=style&index=0&id=ad51caf0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad51caf0",
  null
  
)

export default component.exports