const addLeadingZeros = (num, totalLength) => String(num).padStart(totalLength, '0')

const GenNum = (value, Len) => {
  console.log('GenNum')
  const Arr = []
  // eslint-disable-next-line
  for (let i = 0; i < value; i++) {
    Arr.push({
      Num: addLeadingZeros(i, Len),
      Index: false,
      U3: false,
      T3: false,
      U2: false,
      D2: false,
      U1: false,
      D1: false,
    })
  }
  return Arr
}

const Commas = x => {
  if (x) {
    if (Math.round(x) !== x && x > 0) {
      const Num = Number(x)
      const y = Num.toFixed(2)
      return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return 0
}

export default {
  GenNum,
  addLeadingZeros,
  Commas,
}
